<template>
  <div class="about">
    <h1>This is an about page</h1>
    <button v-on:click="spankShow">test</button>
  </div>
</template>
<script>




import spankpay from "spankpay";

export default {

  mounted () {

  },
  name: 'About',
  methods: {
    spankShow: function () {
      spankpay.show({
        apiKey: 'test_pk_eager_blood_oS0uKnp1S3Re6DbgfpWZGJrxi8Z8FuTf8myhN8te',
        amount: '69.69',
        currency: 'USD',
        fiatEnabled: true,
        fullscreen: true,
        callbackUrl: 'https://172.29.201.40:8081/callback',
        onPayment: function (payment) {
          console.log(`Payment ${payment.status}`, payment)
        }
      })
    }
  }
}
</script>
